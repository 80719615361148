import { Channels } from "src/app/shared/store-mocks/channels.store";
import { Products } from 'src/app/shared/store-mocks/products.store';
import { Directive } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';


export const ValidateChannelProductCombination = (channel: Channels, product: Products):any => {
  if (channel == Channels.WHATSAPP && product == Products.API_SMPP) {
    return { key: 'invalidCombination' }
  }
  if (channel == Channels.WHATSAPP && product == Products.CAMPAIGNS) {
    return { key: 'invalidCombination' }
  }
  return null;
}

@Directive({
  selector: '[appChannelProductCombinationValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: ChannelProductCombinationValidator,
    multi: true
  }]
})
export class ChannelProductCombinationValidator implements Validator {
  validate(control: AbstractControl) : {[key: string]: any} | null {
    const product = control?.value?.products
    const channel = control?.value?.channels

    if(
      (channel == Channels.WHATSAPP && product == Products.API_SMPP)
      || (channel == Channels.VOICE && product == Products.API_SMPP)
    ){
      return { 'invalidProductChannnelCombination': true };
    }


    return null;
  }
}