
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { map } from "rxjs/operators";
import { Channels } from "src/app/shared/store-mocks/channels.store";
import { Products, ProductsMockStore } from "src/app/shared/store-mocks/products.store";
import { UniAuthFacade } from "unifonic-spa-common/src";
import { FiltersFormStore } from "../filter-forms/filter-forms.store";

type State = {

}

const initialState: State = {
}

@Injectable()
export class GeneralFiltersFormProductsStore extends ComponentStore<State>{
    constructor(
        private filtersFormStore: FiltersFormStore,
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }

    //selectors
    flowStudioActivity$ = this.authFacade.featureFlags$.pipe(
        map(ff => !ff['2025-q-1-ci-alaris'])
    )
    campaignActivity$ = this.authFacade.featureFlags$.pipe(
      map(ff => !ff['2025-q-1-ci-alaris'])
    )
    apiActivity$ = this.authFacade.featureFlags$.pipe(
      map(ff => !ff['2025-q-1-ci-alaris'])
    )

    disabled$ = this.select(
        this.flowStudioActivity$,
        this.campaignActivity$,
        this.apiActivity$,
        (flowStudioActivity, campaignActivity, apiActivity) => {
            const disabled = [
                Products.CHATBOT,
                Products.AGENT_CONSOLE
            ]

            if(!flowStudioActivity){
                disabled.push(Products.FLOW_STUDIO)
            }

            if(!campaignActivity){
                disabled.push(Products.CAMPAIGNS)
            }

            if(!apiActivity){
                disabled.push(Products.API)
            }


            return disabled

        }
    )
}
