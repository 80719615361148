import { rest } from 'msw'


const faker = {
  datatype: { uuid: () => ''},
  name: {fullName: () => ''},
  phone: {number: () => ''},
  date: {past: () => '', recent: () => ''},
  helpers: {arrayElement: () => ''},
  lorem: {words: (x) => ''},
  random: {numeric: () => 1}
}


const accounts = {
    generate(number){
        return [...Array(number).fill().map((_ ,i) => ({
          id: i,
          name: 'Test ' + i
          
        })),{
          id:'07173e89-cc6f-446a-980b-d77e16abda5b',
          name: 'Test Sender Name'
        }]
    }
}

export const getSenderNameList = rest.get('http://0.0.0.0:4200/sender-names', async (req, res, ctx) => {
    const numberOfResults = Math.round(Math.random() * (40 - 20) + 20)
    const data = accounts.generate(numberOfResults)
    await new Promise((resolve) =>  setTimeout(() => resolve(), 500))
    return res(
      ctx.json(data)
    )
  })