
<div class="tab-header-selector">
    <uni-buttons>
        <ng-container *ngFor="let button of buttons">
            <button
                type="button"
                (click)="selectTab(button.value)"
                [attr.active]="active === button.value || null"
                [disabled]="button.disabled"
            >
                {{button.label}}
            </button>
        </ng-container>
    </uni-buttons>
</div>