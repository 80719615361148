import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { MessageLogsResponseType } from "../../log-analyser.models";
import { MessageLogsFetchStore } from "../../store/message-logs-fetch.store";
import { UniAuthFacade, UniFeatureFlagsService } from "unifonic-spa-common/src";
import { map } from "rxjs/operators";

type State = {
    messageId: string
    elMessageId: string
    isRealTime: boolean
    submitDate: string
}

const initialState: State = {
    messageId: '',
    elMessageId: '',
    isRealTime: false,
    submitDate: ''
}

@Injectable()
export class MessageLogsTableDetailsStore extends ComponentStore<State>{

    constructor(
        private messageLogsFetchStore: MessageLogsFetchStore,
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }

    //selectors
    readonly messageId$ = this.select(state => state.messageId)
    readonly elMessageId$ = this.select(state => state.elMessageId)
    readonly isRealTime$ = this.select(state => state.isRealTime)
    readonly submitDate$ = this.select(state => state.submitDate)
    // readonly isAlaris$ = this.authFacade.featureFlags$.pipe(
    //     map((ff) => {
    //         return ff['2025-q-1-ci-alaris']
    //     })
    // )
    
    readonly vm$ = this.select(
        this.messageId$,
        this.elMessageId$,
        this.isRealTime$,
        this.submitDate$,
        this.messageLogsFetchStore.isAlaris$,
        this.messageLogsFetchStore.responseType$,
        (messageId, elMessageId, isRealTime, submitDate, isAlaris, type) => {
            return {
                messageId,
                elMessageId,
                isRealTime,
                submitDate,
                isAlaris,
                type: {
                    sms: type === MessageLogsResponseType.Sms,
                    whatsapp: type === MessageLogsResponseType.Whatsapp
                }
            }
        }
    )
}