import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatbotIdSelectorStore } from './chatbot-id-selector.store';
import { ChatbotListFetchStore } from '../../data-access/chatbot-list/chatbot-list.store';
import { ChatbotListRepository } from '../../data-access/chatbot-list/chatbot-list.repository';

@Component({
  selector: 'app-chatbot-id-selector',
  templateUrl: './chatbot-id-selector.component.html',
  styleUrls: ['./chatbot-id-selector.component.scss'],
  providers: [
    ChatbotIdSelectorStore,
    ChatbotListFetchStore,
    ChatbotListRepository
  ]
})
export class ChatbotIdSelectorComponent implements OnInit {


  constructor(
    private store: ChatbotIdSelectorStore
  ) { }

  @Input() set accountId(accountId: string){
    this.store.patchState({accountId: accountId || ''})
  }

  @Input() set typeaheadConfiguration(config: any){
    this.store.patchState({config})
  }

  @Input() set value(label: string){
    this.store.patchState({label})
    this.store.patchState({defaultValue: label})
  }


  @Output() valueChange: EventEmitter<any> = new EventEmitter()

  vm$ = this.store.vm$

  ngOnInit(): void {
    this.store.fetchLatest()
  }

  onValueChange(event: any){
    this.store.onFormValueChange(event)
    this.valueChange.emit(event)
  }

  onTypeaheadEvent(phrase){
    this.store.fetchChatbotsByPhrase(phrase)
  }

  reset(){
    this.store.patchState({label: ''})
  }

  onOptionsClose(){
    this.store.patchState({entities: []})
  }

}
