
    <div class="wrapper">
        <ng-container *ngIf="alternateMenu$ | async">
            <div class="top-bar">
                <uni-top-bar-logo class="top-bar-logo" [logoUrl]="'/assets/images/unifonic_logo_white-new.png'"></uni-top-bar-logo>
            </div>


            <uni-nav>
                <div [style.height.px]="40"></div>
                <uni-nav-category [categoryName]="'REPORTS'">
                    <uni-nav-item [routerLink]="['', 'insights', 'log-analyser']">
                        <uni-nav-item-name>{{'navigation.insights.logAnalyser' | translate}}</uni-nav-item-name>
                    </uni-nav-item>
                    <uni-nav-item [routerLink]="['', 'insights', 'dashboard', 'sms']">
                        <uni-nav-item-name>{{'navigation.insights.dashboard' | translate}}</uni-nav-item-name>
                    </uni-nav-item>
                    <uni-nav-item [routerLink]="['', 'insights', 'reports']">
                        <uni-nav-item-name>{{'navigation.insights.reports' | translate}}</uni-nav-item-name>
                    </uni-nav-item>
                    <uni-nav-item [routerLink]="['', 'security', 'unishield']">
                        <uni-nav-item-name>{{'ci.common.unishield' | translate}}</uni-nav-item-name>
                    </uni-nav-item>
                </uni-nav-category>
        
            </uni-nav>
        </ng-container>
        <div class="screen" [ngClass]="{'ci-alternate': alternateMenu$ | async}">
            <ng-content></ng-content>
        </div>
    </div>



