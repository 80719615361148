
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store'
import { Channels, ChannelsMockStore } from "src/app/shared/store-mocks/channels.store";
import { FiltersFormStore } from "../filter-forms/filter-forms.store";
import { map } from "rxjs/operators";
import { UniAuthFacade } from "unifonic-spa-common/src";

type State = {

}

const initialState: State = {
}

@Injectable()
export class GeneralFiltersFormChannelsStore extends ComponentStore<State>{
    constructor(
        private channelsMockStore: ChannelsMockStore,
        private filtersFormStore: FiltersFormStore,
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }

    //selectors
    whatsappActivity$ = this.authFacade.featureFlags$.pipe(
        map(ff => ff['2023-q-2-ci-whatsapp'] && !ff['2025-q-1-ci-alaris'])
    )
    voiceActivity$ = this.authFacade.featureFlags$.pipe(
      map(ff => ff['2024-q-3-ci-voice'] && !ff['2025-q-1-ci-alaris'])
    )

    channels$ = this.channelsMockStore.viewEntities$.pipe(
        map((channels) => {
          return channels.filter(p => p.value !== Channels.ALL)
        })
    )

    selectedItems$ = this.filtersFormStore.channels$


    disabled$ = this.select(
        this.whatsappActivity$,
        this.voiceActivity$,
        (whatsappActivity, voiceActivity) => {
            const disabled = [
                Channels.PUSH_NOTIFICATION,
                Channels.EMAIL,
                Channels.FACEBOOK,
                Channels.TWITTER
            ]

            if(!whatsappActivity){
                disabled.push(Channels.WHATSAPP)
            }

            if(!voiceActivity){
                disabled.push(Channels.VOICE)
            }

            return disabled

        }
    )
}
