import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { Products, ProductsMockStore } from '../../../shared/store-mocks/products.store';
import { MessageLogsFiltersStore } from '../../store/message-logs-filters.store';
import { FilterPanelProductsStore } from './filter-panel-products.store';

@Component({
  selector: 'app-filter-panel-products',
  templateUrl: './filter-panel-products.component.html',
  styleUrls: ['./filter-panel-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProductsMockStore, FilterPanelProductsStore],
})
export class FilterPanelProductsComponent implements OnInit {

  @Output() valueChange: EventEmitter<void> = new EventEmitter()
  
  products$ = this.store.products$
  productsCurrentValue$ = this.messageLogsFiltersStore.products$

  constructor(

    private messageLogsFiltersStore: MessageLogsFiltersStore,
    private store: FilterPanelProductsStore
  ) { }

  ngOnInit(): void {
  }

  productValueChange(value: string){
    this.messageLogsFiltersStore.clearAdvancedFilters()
    this.messageLogsFiltersStore.patchState({products: value, autoload: true })
  }
}
