
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { CustomerStatus, StatusMockStore } from "src/app/shared/store-mocks/status.store";
import { AdvancedFiltersFormStore } from "../advanced-filters-form/advanced-filters-form.store";
import { FiltersFormStore } from "../filter-forms/filter-forms.store";

type State = {}

const initialState: State = {}

@Injectable()
export class AdvancedFiltersFormStatusAlarisStore extends ComponentStore<State>{

    constructor(
        private filtersFormStore: FiltersFormStore,
        private advancedFiltersFormStore: AdvancedFiltersFormStore,
        private messageLogsStatusMockStore: StatusMockStore
    ){
        super(initialState)
    }

    //selectors
    readonly statusValue$ = combineLatest([
        this.filtersFormStore.status$,
        this.advancedFiltersFormStore.smsStatusAlarisVisibility$
    ]).pipe(
        map(([value, visibility]) => visibility ? value : ''),
    )

    readonly entities$ = this.select(
        this.messageLogsStatusMockStore.smsStatuses$,
        (statuses) => statuses.filter((s:any) => s.value === CustomerStatus.DELIVERED || s.value === CustomerStatus.UNDELIVERED || s.value === CustomerStatus.DELIVERY_NOT_CONFIRMED)
    )
}