<ng-container *ngIf="vm$ | async as vm">
  <uni-section>
    <div class="advanced-filters-opener">
      <div class="advanced-filters-opener-separator"></div>
      <uni-button
        type="button"
        [style]="'link'"
        [isMargin]="true"
        [disabled]="!vm.formActivity"
        (click)="toggleForm()"
      >
        {{'ci.logAnalyzer.forms.advanced.openButton' | translate}}
        <uni-icon-action
          [isDropdown]="true"
          [isActive]="vm.formVisibility"
        ></uni-icon-action>
      </uni-button>
    </div>
  </uni-section>
  <div [hidden]="!vm.formVisibility">
    <div class="row">
      <div class="col-4 col-sm-12"
        *ngIf="vm.senderNameVisibility"
      >
        <app-advanced-filters-form-sender-name
          name="senderName" [ngModel]="vm.senderName" (ngModelChange)="changeSenderName($event)"></app-advanced-filters-form-sender-name>
      </div>


      <!-- @TODO: Will be supported in the future -->
      <!-- <div class="col-4 col-sm-12"
        *ngIf="vm.messageTypeVisibility">
        <app-advanced-filters-form-message-type name="messageType"></app-advanced-filters-form-message-type>
      </div> -->


      <!-- @TODO: Will be supported in the future -->
      <!-- <div class="col-4 col-sm-12"
        *ngIf="vm.campaignStatusVisibility">
        <app-advanced-filters-form-campaign-status name="campaignStatus"></app-advanced-filters-form-campaign-status>
      </div> -->
      <div class="col-4 col-sm-12" *ngIf="vm.senderNumberVisibility">
        <app-advanced-filters-form-sender-number
          [ngModel]="vm.senderNumber"
          name="senderNumber"

          (ngModelChange)="changeSenderNumber($event)"></app-advanced-filters-form-sender-number>
      </div>

      <div class="col-4 col-sm-12"
        *ngIf="vm.campaignIdVisibility">
        <app-advanced-filters-form-campaign-id
          [ngModel]="vm.campaignId"
          [accountId]="vm.accountId"
          name="campaignId" (ngModelChange)="changeCampaignId($event)"
        ></app-advanced-filters-form-campaign-id>
      </div>
      <div class="col-4 col-sm-12"
        *ngIf="vm.smsStatusVisibility">
        <app-advanced-filters-form-status
          [ngModel]="vm.status"
          name="status" (ngModelChange)="changeSmsStatus($event)"
          *ngIf="vm.smsStatusVisibility"
        ></app-advanced-filters-form-status>
      </div>
      <div class="col-4 col-sm-12"
        *ngIf="vm.smsStatusAlarisVisibility">
        <app-advanced-filters-form-status-alaris
          [ngModel]="vm.status"
          name="status" (ngModelChange)="changeSmsStatusAlaris($event)"
          *ngIf="vm.smsStatusAlarisVisibility"
        ></app-advanced-filters-form-status-alaris>
      </div>



      <div class="col-4 col-sm-12" *ngIf="vm.templateVisibility">
        <app-advanced-filters-form-template
          [ngModel]="vm.templates"
          name="templates" (ngModelChange)="changeCampaignTemplate($event)"
        ></app-advanced-filters-form-template>
      </div>

      <div class="col-4 col-sm-12" *ngIf="vm.buttonVisibility">
        <app-advanced-filters-form-button
          [ngModel]="vm.buttons"
          name="buttons" (ngModelChange)="changeCampaignButton($event)"
        ></app-advanced-filters-form-button>
      </div>
      <div class="col-4 col-sm-12" *ngIf="vm.linkVisibility">
        <app-advanced-filters-form-link
          [ngModel]="vm.links"
          name="links" (ngModelChange)="changeCampaignLink($event)"
        ></app-advanced-filters-form-link>
      </div>

      <div class="col-4 col-sm-12" *ngIf="vm.locationVisibility">
        <app-advanced-filters-form-location
          [ngModel]="vm.locations"
          name="locations" (ngModelChange)="changeCampaignLocation($event)"
        ></app-advanced-filters-form-location>
      </div>
      <div class="col-4 col-sm-12"
        *ngIf="vm.whatsappStatusVisibility">

        <app-advanced-filters-form-whatsapp-status
          [ngModel]="vm.status"
          name="status" (ngModelChange)="changeWhatsappStatus($event)"
          *ngIf="vm.whatsappStatusVisibility"></app-advanced-filters-form-whatsapp-status>
      </div>

      <div class="col-4 col-sm-12"
        *ngIf="vm.customerReasonVisibility"
      >
        <app-advanced-filters-form-customer-reason
          [ngModel]="vm.customerReason"
          name="customerReason" (ngModelChange)="changeCustomerReason($event)">
        </app-advanced-filters-form-customer-reason>
      </div>
      <div class="col-4 col-sm-12"
        *ngIf="vm.callerVisibility"
      >
        <app-advanced-filters-form-caller-id
          [ngModel]="vm.caller"
          name="caller" (ngModelChange)="changeCallerId($event)">
        </app-advanced-filters-form-caller-id>
      </div>
      <div class="col-4 col-sm-12"
        *ngIf="vm.directionVisibility"
      >
        <app-advanced-filters-form-direction
          [ngModel]="vm.direction"
          name="direction" (ngModelChange)="changeDirection($event)">
        </app-advanced-filters-form-direction>
      </div>

      <div class="col-4 col-sm-12"
        *ngIf="vm.voiceStatusVisibility">

        <app-advanced-filters-form-voice-status
          [ngModel]="vm.status"
          name="status" (ngModelChange)="changeVoiceStatus($event)"
          *ngIf="vm.voiceStatusVisibility"></app-advanced-filters-form-voice-status>
      </div>
      <div class="col-4 col-sm-12"
      *ngIf="vm.voiceStatusVisibility">
      </div>
    </div>
  </div>
</ng-container>
