import { Injectable } from "@angular/core";
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createChatbotListQuery } from './chatbot-list.graphql';
import { ChatbotListApiResponse } from './chatbot-list.model';
import { omitBy } from "lodash";

@Injectable()
export class ChatbotListRepository {

    constructor(
        private apollo: Apollo
    ){}

    gqlQueryChatbotList(variables, output: string): Observable<ChatbotListApiResponse['findAllChatBotsByAccountID']> {
      return this.apollo.query<ChatbotListApiResponse>({
        query: createChatbotListQuery(
            output
        ),
        variables: {...omitBy(variables, (v) => v === null || v === undefined || v === '')}
      })
      .pipe(
          map((response) => {
            return response.data.findAllChatBotsByAccountID
          }) 
      )
    }
}
