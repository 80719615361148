import { Observable, of } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';


export class CustomUrlInterceptor implements HttpInterceptor{
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const origin = location.origin

    const zainBrowserUrlOrigin = environment.zainBrowserUrlOrigin
    const mainDomain = environment.mainRequestHost
    const zainDomain = environment.zainRequestHost
    let newUrl = req.url
      
        //it is user/me endpoint
    if(req.url.includes(`${environment.ucUrl}/api`)){
        newUrl = req.url.replace(`${environment.ucUrl}/api`, `${environment.ucUrl}/app/api`)
    }
    //if UC account list request
    if(newUrl.includes(`api/v2/accounts/list`) && newUrl.includes(`console-api.${zainDomain}`)){
      newUrl = newUrl.replace(`console-api.${zainDomain}`, zainDomain)
    }
    
    if(origin === zainBrowserUrlOrigin && req.url.includes(mainDomain)){
      newUrl = req.url.replace(mainDomain, zainDomain)

      if(newUrl.includes(`console-api.${zainDomain}`)){
        newUrl = newUrl.replace(`console-api.${zainDomain}`, zainDomain)
      }

    }



    return next.handle(req.clone({url: newUrl}))
  }
}