import { Component, Input, OnInit } from '@angular/core';
import { MessageLogsTableDetailsSmsAlarisStore } from './message-logs-table-details-sms-alaris.store';

@Component({
  selector: 'app-message-logs-table-details-sms-alaris',
  templateUrl: './message-logs-table-details-sms-alaris.component.html',
  styleUrls: ['./message-logs-table-details-sms-alaris.component.scss'],
  providers: [MessageLogsTableDetailsSmsAlarisStore]
})
export class MessageLogsTableDetailsSmsAlarisComponent implements OnInit {

  @Input() set messageId(messageId: string){
    this.store.patchState({messageId})
  }

  @Input() set elMessageId(elMessageId: string){
    this.store.patchState({elMessageId})
  }

  @Input() set isRealTime(isRealTime: boolean){
    this.store.patchState({isRealTime: isRealTime || false})
  }

  @Input() set submitDate(submitDate: string){
    this.store.patchState({submitDate})
  }

  vm$ = this.store.vm$

  constructor(
    private store: MessageLogsTableDetailsSmsAlarisStore
  ) { }

  ngOnInit(): void {
    this.store.fetchData()
  }

  scrollMessageBackward(){
    this.store.scrollMessageBackward()
  }

  scrollMessageForward(){
    this.store.scrollMessageForward()
  }

  scrollMessageTo(messageIndex: number){
    this.store.scrollMessageTo(messageIndex)
  }
}
