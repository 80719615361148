import { Injectable } from "@angular/core"
import { ComponentStore, tapResponse } from "@ngrx/component-store"
import { uniqBy } from "lodash"
import { Observable, of } from "rxjs"
import { map, switchMap, tap, withLatestFrom } from "rxjs/operators"
import { UniAuthFacade } from "unifonic-spa-common/src"
import { getVariablesForSearchByPhrase } from "./chatbot-id-selector.utils"
import { Channels } from "../../store-mocks/channels.store"
import { ChatbotListRepository } from "../../data-access/chatbot-list/chatbot-list.repository"
import { ChatbotListFetchStore } from "../../data-access/chatbot-list/chatbot-list.store"


type State = {
    label: string
    accountId: string
    config: any
    defaultEntities: any[]
    defaultLoading: boolean
    defaultValue: string
    entities: any[]
    limit: number
}

const initialState: State = {
  label: "",
  accountId: '',
  config: {
    noResultMessage: 'ci.common.noResults',
    tooManyItemsMessage: 'ci.common.tooManyItems',
    placeholder: 'ci.common.chatbotName',
    debounceTime: 800,
    minPhraseLength: 1
  },
  defaultEntities: [],
  defaultLoading: false,
  defaultValue: '',
  entities: [],
  limit: 30,
}


@Injectable()
export class ChatbotIdSelectorStore extends ComponentStore<State>{

    constructor(
        private campaignListFetch: ChatbotListFetchStore,
        private campaignListRepository: ChatbotListRepository,
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }

    readonly label$ = this.select(state => state.label)
    readonly accountId$ = this.select(state => state.accountId)
    readonly config$ = this.select(state => state.config)
    readonly defaultEntities$ = this.select(state => state.defaultEntities)
    readonly defaultLoading$ = this.select(state => state.defaultLoading)
    readonly defaultValue$ = this.select(state => state.defaultValue)
    readonly entities$ = this.select(state => state.entities)
    readonly limit$ = this.select(state => state.limit)


    //selectors
    timezone$ = this.authFacade.userMe$.pipe(
        map((user: any) => user.user.timezone)
    )

    readonly campaigns$ = this.select(
        this.defaultEntities$,
        this.entities$,
        (defaultEntities, entities) => uniqBy([...entities, ...defaultEntities], 'value')
    )

    readonly loading$ = this.campaignListFetch.loading$

    vm$ = this.select(
        this.config$,
        this.accountId$,
        this.campaigns$,
        this.defaultLoading$,
        this.label$,
        this.defaultValue$,
        (
            config,
            accountId,
            campaigns,
            campaignsLoading,
            label,
            defaultValue,
        ) => {
            const selectedItem = campaigns.find((c) => c.value === defaultValue)
            return {
                config,
                accountId,
                campaigns,
                campaignsLoading,
                label,
                defaultLabel: selectedItem?.label || '',
                maxSize: 150
            }
        }
    )

    //Updaters
    readonly mergeOptions = this.updater((state, options: any[]) => {
        const uniqueOptions = options.filter((o) => {
            return !state.defaultEntities.find(de => de.value === o.value)
        })

        return {...state, defaultEntities: [ ...state.defaultEntities, ...uniqueOptions]}
    })

    readonly fetchChatbotsByPhrase = this.effect((phrase$: Observable<string>) => {
        return phrase$.pipe(
            tap((phrase) => {
                this.patchState({defaultLoading: true})
            }),
            withLatestFrom(
                this.accountId$,
                this.timezone$
            ),
            switchMap(([botName, accountId, timezone]) => this.campaignListRepository.gqlQueryChatbotList(
                getVariablesForSearchByPhrase(botName, timezone, accountId),
                `id, name`
            ).pipe(
                tapResponse(
                    (response) => {
                        
                        const entities = response.entries.map((e:any) => ({
                            label: e.name,
                            value: e.id
                        }))
                        this.patchState({defaultLoading: false, entities })
                    }, () => {}
                )
            ))
        )
    })

    readonly accountIdChange = this.effect(() => {
        return this.accountId$.pipe(
            tap((accountId: string) => {
                if(!accountId.length){
                    this.patchState({label: ''})
                }
            })
        )
    })

    readonly onFormValueChange = this.effect((event$: Observable<any>) => {
        return event$.pipe(
            tap((event:any) => {
                if(event.label !== ''){
                    this.mergeOptions([event])
                }
                this.patchState({label: event.value})
            })
        )
    })

    readonly fetchLatest = this.effect((trigger$: Observable<void>) => {
        return trigger$.pipe(
            tap(() => {
                this.patchState({defaultLoading: true})
            }),
            withLatestFrom(this.accountId$),
            switchMap(([_, accountId]) => this.campaignListRepository.gqlQueryChatbotList(
                    {accountId, limit: 10},
                    `id, name`
                ).pipe(
                    map((response) => {
                        return response.entries.map((e:any) => ({
                            label: e.name,
                            value: e.id
                        }))
                    }),
                    tapResponse(
                        (entities) => {
                            this.patchState({defaultEntities: entities, defaultLoading: false})
                        },() => {
                            this.patchState({defaultLoading: false})
                        }
                    ),
                    map(() => accountId)
                )
            )

        )
    })
}


