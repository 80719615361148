import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Event, NavigationEnd, NavigationStart, Router, RouterEvent, RouterStateSnapshot, RoutesRecognized } from "@angular/router";
import { ComponentStore } from '@ngrx/component-store';
import { TranslateService } from "@ngx-translate/core";
import { Observable, combineLatest } from "rxjs";
import { combineAll, distinctUntilChanged, filter, pairwise, startWith, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { FeatureFlagKeys, MixpanelEventType, SettingsService, UniAnalyticsService, UniAuthFacade, UniUserpilotService } from "unifonic-spa-common/src";
import { MixpanelEvents } from "./app.models";

type State = {
    currentTitle: string
}

const initialState: State = {
    currentTitle: ''
}

@Injectable()
export class AppStore extends ComponentStore<State>{
    constructor(
        private settings: SettingsService,
        private authFacade: UniAuthFacade,
        private router: Router,
        private analytics: UniAnalyticsService,
        private activateRoute: ActivatedRoute,
        private translate: TranslateService,
        private userpilotService: UniUserpilotService
    ){
        super(initialState)
    }

    userpilotActivity$ = this.select(
        this.authFacade.featureFlags$.pipe(filter(ff => !!ff)),
        (flags) => flags[FeatureFlagKeys.userpilot_2022_q_1]
    )

    readonly initUserPilot = this.effect((trigger$: Observable<void>) => {
        return combineLatest([
            trigger$,
            this.userpilotActivity$
        ]).pipe(
            take(1),
            tap(([_, userpilotActivity]) => {
                if(userpilotActivity){
                    this.userpilotService.initialize(this.settings.settings.userpilotToken)
                }
            })
        )
    })


    //Track Insights Page View on each page change. It omits first page because of pairwaise operator. 
    //We need at least two router events to run the logic as we set Insights Exit Page for previous event, and Insights Page View for current one.
    readonly trackMixpanel = this.effect((trigger$: Observable<void>) => {
        return trigger$.pipe(
            switchMap(() => this.router.events.pipe(
                filter((e) => e instanceof RoutesRecognized),
                distinctUntilChanged((prevEvent: RoutesRecognized, currEvent: RoutesRecognized) => {
                    return this.compareUrls(prevEvent.urlAfterRedirects, currEvent.urlAfterRedirects)
                }),
                pairwise(),
                tap(([prevEvent, currEvent]) => {  
                    const prevTitle = this.getRouterTitle( prevEvent.state.root)
                    const currTitle = this.getRouterTitle( currEvent.state.root)

                    this.analytics.mixpanelTrack(MixpanelEvents.pageExit, {
                        page_title: prevTitle,
                        is_admin: this.authFacade.userMe?.user?.isAdminLevel,
                        account_name: this.authFacade.userMe?.user?.account?.name
                    }); 
                    this.analytics.mixpanelTrack(MixpanelEvents.pageView, {
                        page_title: currTitle,
                        is_admin: this.authFacade.userMe?.user?.isAdminLevel,
                        account_name: this.authFacade.userMe?.user?.account?.name
                    }); 
                    this.analytics.mixpanelTrackTime(MixpanelEvents.pageExit);                     
                })
            ))
        )
    })

    //Track page view for first page - do only once on application load.
    readonly trackMixpanelStartingPage = this.effect((trigger$ : Observable<void>) => {
        return this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd),
            take(1),
            tap((currEvent) => {
                const childRoute = this.getRouterChild(this.activateRoute)
                const translatedTitle = this.getTranslatedTitle(childRoute.snapshot);
                this.analytics.mixpanelTrack(MixpanelEvents.pageView, {
                    page_title: translatedTitle,
                    is_admin: this.authFacade.userMe?.user?.isAdminLevel,
                    account_name: this.authFacade.userMe?.user?.account?.name

                }); 
                this.analytics.mixpanelTrackTime(MixpanelEvents.pageExit); 

            })
        )
    })
    


    private compareUrls(firstUrl, secondUrl){
        const firstEndOfUrl = firstUrl.indexOf('?') === -1 ? firstUrl.length : firstUrl.indexOf('?')
        const secondEndOfUrl = secondUrl.indexOf('?') === -1 ? secondUrl.length : secondUrl.indexOf('?')
        const firstPath = firstUrl.slice(0, firstEndOfUrl)
        const secondPath = secondUrl.slice(0, secondEndOfUrl)

        return firstPath === secondPath
    }


    public getRouterChild(routeEvent){
        let route = routeEvent.firstChild;
        let child = route;
        while (child) {
            if (child.firstChild) {
                child = child.firstChild;
                route = child;
            } else {
                child = null;
            }

        }
        return route;
    }

    public getRouterTitle(routeEvent){
        const route = this.getRouterChild(routeEvent);            
        const title = route.data['titleKey'];
        return this.translate.instant(title);
    }

    public getTranslatedTitle(snapshot: ActivatedRouteSnapshot){
        const title = snapshot.data['titleKey'];
        return this.translate.instant(title);
    }

    

    readonly trackOnDestroy = (title: string) => {
        this.analytics.mixpanelTrack(MixpanelEvents.pageExit, {page_title: title}); 
    }




}
