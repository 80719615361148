import { gql } from "apollo-angular"

export const DEFAULT_OUTPUT = `
    id
    name
    channelConfigured
    status
    createDate
    updateDate
    createdBy
`

export const createChatbotListQuery = (output: string = DEFAULT_OUTPUT) => {
    return gql`
            query FindAllChatBotsByAccountID(
                $accountId: String!
                $limit: Int
                $startDate: String
                $endDate: String
                $botId: Int
                $botName: String
            ){
                findAllChatBotsByAccountID(
                    accountUuid: $accountId
                    limit: $limit
                    startDate: $startDate
                    endDate: $endDate
                    botId: $botId
                    botName: $botName
            ){
                success, message, count, errorCode, entries {
                    ${output}
                }

            }
        }
    `
}
