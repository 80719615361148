import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { TranslateService } from "@ngx-translate/core";

export enum Products {
  ALL = 'ALL',
  CAMPAIGNS = 'CAMPAIGNS',
  CHATBOT = 'CHATBOT',
  FLOW_STUDIO = 'FLOW_STUDIO',
  AGENT_CONSOLE = 'AGENT_CONSOLE',
  API_SMPP = 'API_SMPP',
  API = 'API'
}

export enum ProductsLabels {
  campaign = 'Campaign',
  chatbot = 'Chatbot',
  flowstudio = 'Flow Studio',
  conversation = 'Conversation',
  'Api and Smpp' = 'API and SMPP'
}

export type ProductsKeys = keyof typeof Products;

type State = {
    loading: boolean
    loaded: boolean
    entities: {
      label: string,
      value: string
    }[]
}

const initialState: State = {
    loading: false,
    loaded: true,
    entities: [{
        label: 'All',
        value: Products.ALL
      },{
        label: 'Campaigns',
        value: Products.CAMPAIGNS
      },{
        label: 'Api Smpp',
        value: Products.API_SMPP
      },{
        label: 'Api',
        value: Products.API
      },{
        label: 'Chatbot',
        value: Products.CHATBOT
      },{
        label: 'Flow Studio',
        value: Products.FLOW_STUDIO
      },{
        label: 'Agent Console',
        value: Products.AGENT_CONSOLE
      }
    ]
}

@Injectable()
export class ProductsMockStore extends ComponentStore<State>{
    constructor(
      private translateService: TranslateService,
    ){
        super(initialState)
    }

    //selectors
    loading$ = this.select(state => state.loading)
    loaded$ = this.select(state => state.loaded)
    entities$ = this.select(state => state.entities)

    viewEntities$ = this.select(
      this.entities$,
      (entities) => {
        return entities.map(e => {
          return {
            ...e,
            label: this.translateService.instant('ci.common.brand.' + e.value.toLowerCase())
          }
        })
      }
    )

    //updaters
    setEntities = this.updater((state, entities: any[]) => {
      return {...state, entities}
    })
}
