
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { map } from "rxjs/operators";
import { Products, ProductsMockStore } from "src/app/shared/store-mocks/products.store";
import { UniAuthFacade } from "unifonic-spa-common/src";

type State = {

}

const initialState: State = {
}

@Injectable()
export class FilterPanelProductsStore extends ComponentStore<State>{
    constructor(
        private productsMockStore: ProductsMockStore,
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }

    //selectors
    flowStudioActivity$ = this.authFacade.featureFlags$.pipe(
        map(ff => !ff['2025-q-1-ci-alaris'])
    )
    campaignActivity$ = this.authFacade.featureFlags$.pipe(
      map(ff => !ff['2025-q-1-ci-alaris'])
    )
    apiActivity$ = this.authFacade.featureFlags$.pipe(
      map(ff => !ff['2025-q-1-ci-alaris'])
    )

    products$ = this.select(
        this.productsMockStore.viewEntities$,
        this.flowStudioActivity$,
        this.campaignActivity$,
        this.apiActivity$,
        (products, flowStudioActivity, campaignActivity, apiActivity) => {

         return products.filter(p => 
            p.value === Products.ALL
            || (p.value === Products.CAMPAIGNS && campaignActivity)
            || (p.value === Products.FLOW_STUDIO && flowStudioActivity)
            || p.value === Products.API_SMPP
            || (p.value === Products.API && apiActivity)) 

        }
    )
}
